import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'prismic'
import { prismicRepo } from '../../prismic-configuration'

import NotFound from 'views/not-found/NotFound'

export const query = graphql`
  query NotFoundPageQuery {
    prismicNotFoundPage(uid: { eq: "404" }, lang: { eq: "en-us" }) {
      _previewable
      uid
      data {
        page_meta_description
        page_meta_title
        page_meta_thumbnail {
          url
        }
        title
        description {
          richText
          text
        }
      }
    }
  }
`

export default withPrismicPreview(NotFound, [
  {
    repositoryName: prismicRepo,
    linkResolver,
  },
])
