import React, { useEffect } from 'react'
import classNames from 'classnames/bind'

import { useThemeMode } from 'components/ui/layout/ThemeMode'

import Layout from 'layouts'
import SEO from 'components/seo'
import Container from 'components/ui/layout/Container'

import useUIContext from 'context/ui'

import HeroText from 'components/ui/hero-text'

import * as s from './NotFound.module.css'

const cn = classNames.bind(s)

const NotFound = ({ data }) => {
  const page = data?.prismicNotFoundPage?.data
  if (!page) return null

  const isMobileMenuOpen = useUIContext(s => s.isMobileMenuOpen)
  const setisLabTransitionRunning = useUIContext(s => s.setisLabTransitionRunning)
  const setShowBubbles = useUIContext(s => s.setShowBubbles)
  const showBubbles = useUIContext(s => s.showBubbles)
  const setShowBackgroundBubbles = useUIContext(s => s.setShowBackgroundBubbles)
  const isPageTransitionActive = useUIContext(s => s.isPageTransitionActive)

  useThemeMode(isPageTransitionActive ? '' : 'dark')

  useEffect(() => {
    setisLabTransitionRunning(false)
    setShowBackgroundBubbles(true)
    return () => {
      setisLabTransitionRunning(false)
      setShowBubbles(0)
      setShowBackgroundBubbles(false)
    }
  }, [])

  // show bubbles after mount
  useEffect(() => {
    let delay = showBubbles === 0 ? 2000 : 1000 // delay when leaving page
    let timer = setTimeout(() => setShowBubbles(1), delay)
    return () => {
      clearTimeout(timer)
    }
  }, [isPageTransitionActive, isMobileMenuOpen])

  const metaTitle = page?.page_meta_title
  const metaDescription = page?.page_meta_description
  const metaThumbnail = page?.page_meta_thumbnail

  return (
    <Layout isLabPage className={cn('NotFound')} fadeIn={false} background="#000" footer={false} theme="dark">
      <SEO title={metaTitle} description={metaDescription} thumbnail={metaThumbnail} />
      <Container className={cn('hero')}>
        <HeroText className={cn('heroText')} text={page?.title} description={page?.description} />
      </Container>
    </Layout>
  )
}

export default NotFound
